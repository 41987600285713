import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";


@Injectable({
  providedIn: "root",
})
export class UrlService {
  constructor() {
  }

  public previousUrl: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public previousUrl$: Observable<string> = this.previousUrl.asObservable();

  private currentUrl: BehaviorSubject<string> = new BehaviorSubject<string>("");
  public currentUrl$: Observable<string> = this.currentUrl.asObservable();

  setCurrentUrl(currentUrl: string) {
    this.currentUrl.next(currentUrl);
  }

  setPreviousUrl(previousUrl: string) {
    this.previousUrl.next(previousUrl);
  }
}
